
.portfolio-showcase {
  width: 85%;
  height: 50vw;
  min-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-color:black;
  border-radius:10px;
  border-width: 4px;
}

.portfolio-showcase-container {
  display:flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.portfolio-thumbnail {
  border-radius: 8px;
  border-width: 4px;
  padding-left: .8vw;
  padding-right: .8vw;
  padding-top: .8vw;
  margin: 2px;
  background-color:rgb(230, 230, 230);
  width: max(25vw, 250px);
  height: max(25vw, 250px);
}

.portfolio-thumbnail:hover {
  background-color: rgb(196, 196, 196);
  font-weight: 1000;
}


.portfolio-thumbnail-img {
  border-radius: 5px;
  border-width: 4px;
}

.portfolio-thumbnail-title {
  font-family: "Rajdhani";
  font-size: 3vw;
}

.project-details {
  display:flex;
  align-items: center;
  justify-content: center;
  width:90%;
  margin: 10px;
  flex-direction: column;
  background-color: white;
  border: 1px solid;
  border-width: thin;
  border-radius: 20px;
}

.rect-img-container {
  position: relative;
}

.rect-img-container::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rect-img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
  padding: 3px;
}