.resume {
    border: 1px solid;
    border-width: thin;
    border-radius: 10px;
    margin: 20px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    background-color: white;
  }

.resume-flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 110vh;
  width: 80vw;
}

.resume-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.resume-download-text {
  text-decoration: none;
}

.resume-download {
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white !important;
}
