.project-details-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.project-details-title-container {
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.project-details-title {
    display: flex;
    width: 95%;
    align-items: center;
    justify-content: center;
}

.force-square-sized {
    object-fit: cover; 
    border-radius: 1px;
    border-color: black;
    border: 1px solid;
    border-width: thin;
    max-height: 50vw;
}

.project-details-primary-carousel {
    display: block;
    width: 60%;
    float: left;
    shape-outside: content-box;
    shape-margin: 15px;
    margin: 10px;
    height: 400px;
    overflow-y: hidden;
}

.project-details-secondary-carousel {
    width: 30vw;
    height: 50vw;
    overflow-y: hidden;
}

.project-details-picture {
    display: block;
    width: 60%;
    float: left;
    shape-outside: content-box;
    shape-margin: 15px;
    margin: 15px;
}

.project-details-video {
    display: block;
    width: 60%;
    height: 40vw;
    max-height: 500px;
    float: left;
    shape-outside: content-box;
    shape-margin: 15px;
    margin: 10px;
}

.project-details-description {
    margin: 10px;
    padding-left: 30px;
}