.fade-in-container {
  display: flex;
  flex-direction: column;
}

.fade-in-item {
  opacity: 0;
  animation-name: fade;
  animation-duration: .5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards; /* Add this line */
}

.fade-in-item:nth-child(1) {
  animation-delay: .1s;
}

.fade-in-item:nth-child(2) {
  animation-delay: 1s;
}

.fade-in-item:nth-child(3) {
  animation-delay: 2s;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(20px); /* Initial position (move up) */

  }
  to {
    opacity: 1;
    transform: translateY(0); /* Final position (back to normal) */

  }
}
