.root-div {
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 100%;
}

.column-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  background-image: url("../assets/style/triangles1.png");
  background-repeat:no-repeat;
  background-size: cover;
  width:100%;
  height:100%;
  padding-left: 10%;
  padding-right: 10%;
}

@media (max-width: 500px) {
  .column-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    background-image: url("../assets/style/triangles1.png");
    background-repeat:no-repeat;
    background-size: cover;
    width:100%;
    height:100%;
  }
}

.column-container-resume {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  background-image: url("../assets/style/gulf-gradient-reverse.png");
  background-repeat:no-repeat;
  background-size: cover;
  width:100%;
  height:100%;
}

.profile-picture {
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 500px) {
  .profile-picture {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
  }
}

.picture-frame {
  display:flex;
  justify-content: right;
  align-items: center;

}

.text-outline {
  color: white;
  text-shadow:
  -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
  1px 1px 0 #000;
}

.profile-info {
  display:flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .profile-info {
    display:flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 70px;
    height: 30%;
  }
}

.profile-info-text {
  font-size: min(7.5vw, 90px);
}

.appbar {
  display: flex;
  background-color: white;
  position: sticky;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  text-emphasis-color: white;
  text-decoration-color: white;
}

.flex {
  display: flex;
  width: 100%;
}

.base {
  height: 900px;
}

@media (max-width: 350px) {
  .base {
    height: 720px;
  }
  
}

.navbar-option {
  width: 30%;
}

@media (max-width: 540px) {
  .navbar-option {
    width: 20%;
  }
}

.portfolio-filters-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 75%;
  flex-wrap: wrap;
  flex-direction: row;
}

.rajdhani-font {
  font-family: "Rajdhani", sans-serif;
}

.portfolio {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color:white;
  width: 100%;
}

.subheader-label {
  height:100%;
  width: 120%;
  background-color: rgb(41, 133, 193);
  text-align: left;
  text-indent: 20%;
  margin-right: 50%;
  font-size: 20;
  font-size: min(4.5vw, 30px);
  width:100%;
}

.framework-filter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  flex-flow: wrap;
  border-radius: 2px;
  border-color: black;
  margin: 5px;
}

.portfolio-showcase-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
}

.resume-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.contact-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}