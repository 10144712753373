.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
  }

.bordered {
  border: 20px;
  border-color: black;
}

.portfolio-filter-icon {
  min-width: 70px;
  margin-right: auto;
}

img {
  min-width: 50px;
}