.column-container-bio {
    display: flex;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    background: rgb(112,174,240);
    background: radial-gradient(circle, rgba(112,174,240,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 79%, rgba(199,225,255,1) 86%, rgba(112,174,240,1) 95%, rgba(112,174,240,1) 100%);

    /* background: rgb(112,174,240);
    background: radial-gradient(circle, rgba(112,174,240,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(205,214,226,1) 79%, rgba(199,225,255,1) 86%, rgba(112,174,240,1) 95%, rgba(112,174,240,1) 100%); */

    width:100%;
    height:100%;
  }

  .rajdhani-font {
    font-family: "Rajdhani", sans-serif;
    font-weight: 900;
  }

  .bio-info-text {
    font-size: min(5.5vw, 20px);
    width:100%;
    padding-right: 10%;
  }

  .contact-bio {
    text-align: center;
    margin: 50px;
    padding: 30px;
  }

  
  